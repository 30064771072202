import './App.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader2 } from "lucide-react"
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as BigLogo } from './images/tta-logo.svg';
import LocationDetector from './components/LocattionDetactor';
import Conversation from './components/Conversation';
import Cookies from 'js-cookie';
import BgDesign from './images/bg-design.webp'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function WidgetPage() {
    const questions = [
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?'
    ];
    const [preview, setPreview] = useState(true);
    const [randomId, setRandomId] = useState('')
    const query = useQuery();
    const id = query.get('id');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [promptLoading, setPromptLoading] = useState(false)
    const [prompt, setPrompt] = useState(false)
    const [conversationId, setConversationId] = useState('')
    const [aiAnswer, setAiAnswer] = useState('')
    const [errorResponse, setErrorResponse] = useState(null)
    const [showForm, setShowForm] = useState(true)
    const [showFormLast, setShowFormLast] = useState(false)
    const [resultQuestion, setResultQuestion] = useState([])
    function generateRandomId(length = 8) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        result += chars[Math.floor(Math.random() * 52)];
        for (let i = 1; i < length; i++) {
          result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }
    useEffect(() => {
        const fetchData = async () => {
          if (id) {
            const requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };
            try {
              const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/getDesignSettingsForChatbot?project_id=${id}`, requestOptions);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              setData(result);
            } catch (error) {
              setError(error.message);
            } finally {
              setLoading(false);
            }
          }
        };
        fetchData();
        const fetchData2 = async () => {
            if (id) {
              const requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              try {
                const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/getLeadLabel?project_id=${id}`, requestOptions);
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setFormData(result);
              } catch (error) {
                console.error('Error fetching lead label:', error);
              }
            }
        };
        fetchData2();
        setRandomId(generateRandomId())
    }, [id]);
    function getDeviceType() {
        const width = window.innerWidth;
        if (width <= 767) return 'Mobile';
        if (width <= 1024) return 'Tablet';
        return 'Desktop';
    }
    function getBrowserType() {
        const userAgent = navigator.userAgent;
        let browserName;
        if (userAgent.match(/chrome|chromium|crios/i)) {
          browserName = "Chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
          browserName = "Firefox";
        } else if (userAgent.match(/safari/i)) {
          browserName = "Safari";
        } else if (userAgent.match(/opr\//i)) {
          browserName = "Opera";
        } else if (userAgent.match(/edg/i)) {
          browserName = "Edge";
        } else if (userAgent.match(/msie|trident/i)) {
          browserName = "Internet Explorer";
        } else {
          browserName = "Unknown";
        }
        return browserName;
    }
    function getOS() {
        const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      
        let os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(platform)) {
          os = 'Linux';
        }
        return os;
    }
    const screenSize = getDeviceType();
    const browserType = getBrowserType();
    const operatingSystem = getOS();
    const country = LocationDetector();
    const handleMessage = async (text) => {
        setAiAnswer('')
        setPromptLoading(true);
        setPrompt(text)
        setInputValue('')
        setPreview(false)
        setShowForm(false)
        const customerId = Cookies.get(`customerId-${id}`);
        const raw = JSON.stringify({
            "website_id": id,
            "customer_user_id": customerId ? JSON.parse(customerId) : randomId,
            "prompt": text,
            "browser_type": browserType,
            "country": country,
            "device_type": screenSize,
            "domain": window?.location?.hostname,
            "landing_page": window?.location?.pathname,
            "os_type": operatingSystem,
            "reference": null
        });
        const requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };
        try {
            const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/QueryLLM`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setConversationId(result.conversation_id)
            setAiAnswer(result.response)
            if (showFormLast) {
              setShowForm(result.show_lead_form)
            } else {
              setShowForm(true)
            }
            if (result.suggested_questions) {
              setResultQuestion(result.suggested_questions)
            }
            if (result.response) {
              setMessages(prevMessages => 
                  prevMessages.map((msg, index) => 
                      index === prevMessages.length - 1 && msg.type === 'ai' && msg.content === 'loading'
                          ? { ...msg, content: result.response }
                          : msg
                  )
              );
          }
        } catch (error) {
            console.error('Error fetching lead label:', error);
            setErrorResponse('There was an error getting response')
            if (!showFormLast) {
              setShowForm(true)
            }
        } finally {
            setPromptLoading(false);
            setPrompt('')
        }
    }
    const [inputValue, setInputValue] = useState('');
    const handlePrompt = (e) => {
        e.preventDefault();
        if (e.target.prompt.value.length !== 0) {
            handleMessage(e.target.prompt.value);
        }
    }
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        const savedMessages = Cookies.get(`conversationMessages-${id}`);
        if (savedMessages) {
          setMessages(JSON.parse(savedMessages));
          if(JSON.parse(savedMessages).length > 0) {
            setPreview(false)
          }
        }
    }, []);
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        if (e.shiftKey) {
          return;
        }
        e.preventDefault();
        handleMessage(inputValue);
      }
    };
    useEffect(() => {
      const customerId = Cookies.get(`customerId-${id}`);
      if(randomId && !customerId) {
        Cookies.set(`customerId-${id}`, JSON.stringify(randomId), { expires: 0.5 });
      }
    }, [messages]);

    return ( error ? 
        <div className='flex flex-col min-h-screen items-center justify-center relative bg-white'>
            <h2 className='text-3xl font-semibold mb-2'>There was an error with this chat bot please try later !!</h2>
            <p className='text-red-700 font-medium'>{error}</p>
        </div> : loading || !data ? <div className='min-h-screen flex items-center justify-center relative bg-white'>
          <div className="balls">
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div> :
        <div className={`flex items-center main-screen h-screen flex-col ${preview ? 'justify-center' : ''} relative bg-white`}>
            <img src={BgDesign} alt='BgDesign' className='bg-design' /> 
            {preview ? data?.brand_logo_url ? <img className='absolute logo top-6 left-6 md:top-8 md:left-8 max-w-[120px] max-h-[40px] object-cover' src={data.brand_logo_url} alt="logo" /> :
                <Logo className='absolute top-6 left-6 md:top-8 md:left-8 logo' /> : data.brand_logo_url ? <img src={data.brand_logo_url} alt="logo" className='absolute top-6 logo left-6 md:top-8 md:left-8 hidden lg:block max-h-[40px] max-w-[120px] object-cover' /> :
                <Logo className='absolute top-6 left-6 md:top-8 md:left-8 hidden lg:block logo' />
            }
            {preview ?
                <div className='mt-5 md:mt-0 w-[786px] max-w-full p-5'>
                    <h1 className='fade-up-div text-[#0F172A] relative z-[1] text-3xl lg:leading-[1.3] leading-[1.3] lg:text-[46px] font-semibold lg:font-bold mb-5' style={{color : data?.AI_brand_color ? data?.AI_brand_color : ''}}>
                        Welcome!
                        <span className='block text-gray-300 tta--gradient--text'>{data?.AI_initial_message ? data.AI_initial_message : 'How can I help you today?'}</span>
                    </h1>
                    <form className={`fade-up-div relative second mb-8 w-[746px] max-w-full tta-input-form p-[1.5px] rounded-[7px]`} onSubmit={(e) => {handlePrompt(e)}}>
                        <textarea onKeyDown={handleKeyDown} disabled={promptLoading} value={inputValue} onChange={(e) => setInputValue(e.target.value)} type='text' id='prompt' name='prompt' placeholder={data?.AI_message_placeholder ? data.AI_message_placeholder : 'Enter your question here...'} className={`md:h-[122px] mb-[-6.25px] resize-none h-[42px] focus-visible:outline-[#8451D4] focus:outline-[#8451D4] active:outline-[#8451D4] w-full px-4 md:py-3 py-[6px] pr-14 rounded-md border border-slate-300 text-[#1E293B] text-sm font-normal leading-normal ${promptLoading && 'cursor-not-allowed'}`} >
                        </textarea>
                        <button disabled={promptLoading} type='submit' className={`w-[30px] h-[30px] absolute rounded-[8px] right-3 md:top-3 top-[5.5px] ${promptLoading && 'cursor-not-allowed'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill={"#6A47F2"} >
                            <rect x="0.471436" y="0.518311" width="28" height="28" rx="8" fill="#6A47F2"/>
                            <path d="M15.1654 10.3501L19.3335 14.5182M19.3335 14.5182L15.1654 18.6862M19.3335 14.5182L9.60802 14.5182" stroke="white" strokeWidth="1.39693" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </button>
                        <div className="h-[19.21px] justify-center items-center gap-[5px] inline-flex pointer-events-none absolute right-3 bottom-2">
                          <p className="text-slate-400 text-[10.81px] font-normal leading-3">Use </p>
                          <div className="px-[4.50px] py-[3.60px] bg-slate-200 rounded justify-center items-center gap-[9.01px] flex">
                            <p className="text-slate-600 text-[10.81px] font-normal leading-3">shift + return</p>
                          </div>
                          <p className="text-slate-400 text-[10.81px] font-normal leading-3">for new line</p>
                        </div>
                    </form>
                    <div className='fade-up-div third'>
                      <h3 className='text-slate-900 text-[15px] font-semibold mb-4'>Questions you might be thinking about</h3>
                      <div className={`${preview ? '' : 'show'} justify-start items-center flex-col flex w-full max-w-full overflow-x-scroll visible-area border-t border-[#E2E8F0]"`}>
                        {(data?.AI_suggested_prompt ? data?.AI_suggested_prompt : questions).map((question, index)=>(
                            <button onClick={() => handleMessage(question)} className="cursor-pointer justify-center items-center flex w-full" key={index}>
                                <div className="grow shrink basis-0 self-stretch py-3 border-b border-[#E2E8F0] color-p w-full justify-between items-center gap-4 flex">
                                  <p className="self-stretch text-[#1E293B] text-sm font-medium leading-normal text-left"><span className='mr-1.5 text-lg'>🤝</span> {question}</p>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.50024 4.76758V14.8926" stroke="#334155" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.43774 9.83008H14.5627" stroke="#334155" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </div>
                            </button>
                        ))}
                      </div>
                    </div>
                </div> :
                <>
                  <Conversation resultQuestion={resultQuestion} handleMessage={handleMessage} questionAi={data?.AI_suggested_prompt} setShowFormLast={setShowFormLast} showForm={showForm} setShowForm={setShowForm} setMessages={setMessages} messages={messages} showAvatar={data.avatar_logo_toggle} image={data?.avatar_logo_url} prompt={prompt} errorResponse={errorResponse} randomId={randomId} conversationId={conversationId} aiAnswer={aiAnswer} promptLoading={promptLoading} formData={formData} id={id} />
                  <div className='w-[786px] max-w-full px-5 h-fit mt-auto mb-2.5 md:mb-0'>
                    <form className={`relative tta-input-form mt-auto p-[1px] rounded-[7px]`} onSubmit={(e) => {handlePrompt(e)}}>
                        <input disabled={promptLoading} value={inputValue} onChange={(e) => setInputValue(e.target.value)} type='text' id='prompt' name='prompt' placeholder={data?.AI_message_placeholder ? data.AI_message_placeholder : 'Enter a prompt here'} className={`md:h-[52px] bg-white h-[42px] focus-visible:outline-[#8451D4] focus:outline-[#8451D4] active:outline-[#8451D4] w-full px-4 md:py-3 py-[6px] pr-14 rounded-md border border-slate-300 text-[#1E293B] text-sm font-normal leading-normal ${promptLoading && 'cursor-not-allowed'}`} />
                        <button disabled={promptLoading} type='submit' className={`md:w-[30px] md:h-[30px] w-[24px] h-[24px] absolute rounded-[8px] right-3 md:top-3 top-[5.5px] ${promptLoading && 'cursor-not-allowed'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                              <rect x="0.0690918" y="0.72168" width="28" height="28" rx="8" fill={"#6A47F2"} />
                              <path d="M9.90039 14.0269L14.0685 9.85889M14.0685 9.85889L18.2365 14.0269M14.0685 9.85889L14.0685 19.5844" stroke="white" strokeWidth="1.39693" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </form>
                  </div>
                </>
            }
            <div className='w-[786px] max-w-full px-5 sticky top-[100%] pb-2.5 lg:pt-2'>
                <div className='bottom-line text-center'>
                    <div className='text-gray-700 text-[9px] lg:text-xs font-normal leading-tight' dangerouslySetInnerHTML={{ __html: data?.AI_footer_message }} />
                    <div className="mt-1 flex xl:hidden mx-auto items-center gap-2 justify-center">
                        <div className="text-gray-600 text-[9px] lg:text-[13px] font-normal leading-[18px]">Powered by</div>
                        <BigLogo className='w-[70px]' />
                    </div>
                </div>
            </div>
            <div className="w-[208px] h-[30px] px-2.5 py-1.5 rounded justify-start items-center gap-2 xl:inline-flex absolute hidden bottom-5 right-5">
                <div className="text-gray-600 text-[13px] font-normal leading-[18px]">Powered by</div>
                <a href='https://www.talktoagent.com/' target='_blank'>
                  <BigLogo />
                </a>
            </div>
        </div>
    );
}

export default WidgetPage;
